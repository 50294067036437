import React from 'react';
import './assets/HeroSection.scss';
import {PropertyDetails, PropertyVideo, Video} from "@renaissancerentals/renaissance-component";

export const HeroSection: React.FC<HeroSectionProps> = ({property}) => {
    return (
        <section className="section-hero">
            {property.coverVideo ? <PropertyVideo coverVideo={property.coverVideo}/> : <Video
                url="https://www.googleapis.com/drive/v3/files/1oZIMwqKLfB-2F-wtNlfFkAfdwx99OaKd?alt=media&key=AIzaSyAdG4u5YD2CZvQTv_hRtaKrmSNWZkY30oU"/>}

        </section>
    );
}

export interface HeroSectionProps {
    property: PropertyDetails;
}
